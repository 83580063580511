import React from "react";
import NavTermek from "./components/Nav";
import Info from "./components/Info";
import Hero from "./components/Hero";
import FooterTermek from "./components/Footer";
import Popup from "../../components/Popup";

const TermekeinkPage = () => {
  
  return (
    <div>
        <body>
          <div class="termekeink-wrap">
            <NavTermek/>
            <Info/>
            <Hero/>
            <FooterTermek/>
          </div>
          <Popup/>
        </body>
    </div>
  );
};

export default TermekeinkPage;