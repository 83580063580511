import React, { createContext, useContext, useState, useEffect } from 'react';

const BaseContext = createContext(null);

export const useBase = () => useContext(BaseContext);

export const BaseProvider = ({ children }) => {
    const [popupOpen,setPopupOpen] = useState(false);
    const openPopup = () => {
        setPopupOpen(true);
        console.log(popupOpen);
    }
    const closePopup = () => {
        setPopupOpen(false);
    }
    const [popupType,setPopupType] = useState(1);
    const [info, setInfo] = useState(false);
    const [transStyle, setTransStyle] = useState({
        display: "block",
        WebkitTransform: "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        MozTransform: "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        MsTransform: "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        transform: "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        transition: 'transform 0.7s ease-in-out',
        backgroundColor: "#ffffff",
        animation: "colorChange 75s infinite"
    });
    const [quantity, setQuantity] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    taxNumber: '',
    country: '',
    city: '',
    postalCode: '',
    address: '',
    notes: ''
  });

  const handleQuantityChange = (delta) => {
    setQuantity((prevQuantity) => Math.min(50, Math.max(1, prevQuantity + delta)));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

    const colors = [
        "#FF5733", "#33FF57", "#3357FF", "#F3FF33", "#FF33A6",
        "#33FFF3", "#F333FF", "#33F3FF", "#FF5733", "#33FF57",
        "#3357FF", "#F3FF33", "#FF33A6", "#33FFF3", "#F333FF"
    ];

    useEffect(() => {
        const styleSheet = document.styleSheets[0];
        const keyframes = `
            @keyframes colorChange {
                ${colors.map((color, index) => `${(index / colors.length) * 100}% { background-color: ${color}; }`).join('')}
            }
        `;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    }, [colors]);

    const showInfo = () => {
        setInfo(true);
        setTransStyle((prev) => ({ ...prev, display: "block", transform: "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" }));
    }

    const hideInfo = () => {
        setInfo(false);
        setTransStyle((prev) => ({ ...prev, display: "block", transform: "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" }));
    }

    const toggleInfo = () => {
        if (info) {
            hideInfo();
        } else {
            showInfo();
        }
    }

    const contextValue = {
        transStyle, showInfo, hideInfo, toggleInfo,
        quantity,setQuantity,formData,setFormData,handleQuantityChange,handleChange,
        popupOpen,setPopupOpen,popupType,setPopupType, openPopup,closePopup
    };

    return <BaseContext.Provider value={contextValue}>{children}</BaseContext.Provider>;
};