import React, { useRef, useState } from 'react';
import './Popup.css';
import StripePayment from './StripePayment';
import { useBase } from '../contexts/BaseContext';

const Popup = ({ onClose }) => {
    const stripeRef = useRef(null);
    const {quantity,setQuantity,formData,setFormData,handleQuantityChange,handleChange,popupOpen,setPopupOpen,popupType,setPopupType} = useBase();
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, phone, country, city, postalCode, address } = formData;
    if (!name || !email || !phone || !country || !city || !postalCode || !address) {
      alert('Kérlek töltsd ki az összes kötelező mezőt!');
    } else {
      // Itt lehet kezelni az adatokat, pl. elküldeni egy szerverre
      console.log("STRIPE");
      stripeRef.current.submit();
    }
  };

  return (<>
    {popupOpen && <div className="popup-overlay">
      <div className="popup">
        <button className="close-button" onClick={()=>setPopupOpen(false)}>X</button>
        <div className="popup-content">
          <div className="product-card">
            <div className="card-header">
              <h2>Amarenti</h2>
              <div className="separator"></div>
            </div>
            { popupType === 1 ?
            <p>Black T-shirt<br /></p> 
            :
            <p>Midnightblue T-shirt<br /></p> 
            }
            <div className="image-container">
              { popupType === 1 ? 
                <img 
                src="images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2.webp" 
                alt="Amarenti Black T-shirt" 
                srcSet="images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2-p-500.webp 500w, images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2-p-800.webp 800w, images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2-p-1080.webp 1080w, images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2.webp 1380w" 
                className="product-image" 
              />
              :
              <img 
              src="images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2.webp" 
              loading="lazy" 
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 38vw, 36vw" 
              alt="" 
              srcSet="images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2-p-500.webp 500w, images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2-p-800.webp 800w, images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2-p-1080.webp 1080w, images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2.webp 1380w" 
              className="termekkep2rolunk" 

              />
              }
            </div>
            <StripePayment ref={stripeRef}/>
          </div>
          <div className="form-section">
            <div className="quantity-selector">
              <button onClick={() => handleQuantityChange(-1)}>-</button>
              <input type="number" value={quantity} readOnly />
              <button onClick={() => handleQuantityChange(1)}>+</button>
            </div>
            <div className="form-container">
              <form className="popup-form" onSubmit={handleSubmit}>
                <label>
                  Név:
                  <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </label>
                <label>
                  Email:
                  <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </label>
                <label>
                  Telefonszám:
                  <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                </label>
                <label>
                  Cégnév (opcionális):
                  <input type="text" name="company" value={formData.company} onChange={handleChange} />
                </label>
                <label>
                  Adószám (opcionális):
                  <input type="text" name="taxNumber" value={formData.taxNumber} onChange={handleChange} />
                </label>
                <label>
                  Ország:
                  <input type="text" name="country" value={formData.country} onChange={handleChange} required />
                </label>
                <label>
                  Város:
                  <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                </label>
                <label>
                  Irányítószám:
                  <input type="text" name="postalCode" value={formData.postalCode} onChange={handleChange} required />
                </label>
                <label>
                  Cím:
                  <input type="text" name="address" value={formData.address} onChange={handleChange} required />
                </label>
                <label>
                  Megjegyzés (opcionális):
                  <textarea name="notes" value={formData.notes} onChange={handleChange}></textarea>
                </label>
              </form>
            </div>
            <div className="stripe-placeholder"></div>
            <button className="submitbutton" type="submit" onClick={handleSubmit}>Vásárlás</button>
          </div>
        </div>
      </div>
    </div> } </>
  );
};

export default Popup;