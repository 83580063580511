import React, { useEffect, useState } from "react";

const Rolunk = () => {
  const [scaleDone, setScaleDone] = useState(false);
  const [showLetters, setShowLetters] = useState(Array(6).fill(false));
  const [showLine, setShowLine] = useState(false);
  const [showSubElements, setShowSubElements] = useState(Array(8).fill(false));

  const transformStyle1 = {
    WebkitTransform: "translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MozTransform: "translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MsTransform: "translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transform: "translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    opacity: 0
  };

  const transformStyle2 = {
    WebkitTransform: "translate3d(-105%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MozTransform: "translate3d(-105%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MsTransform: "translate3d(-105%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transform: "translate3d(-105%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
  };

  const transformStyle3 = {
    WebkitTransform: "translate3d(0, 0, 0) scale3d(1.2, 1.2, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MozTransform: "translate3d(0, 0, 0) scale3d(1.2, 1.2, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MsTransform: "translate3d(0, 0, 0) scale3d(1.2, 1.2, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transform: "translate3d(0, 0, 0) scale3d(1.2, 1.2, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
  };

  useEffect(() => {
    const timers = [];
    timers.push(setTimeout(() => setScaleDone(true), 2000)); // Wait for scale down to complete

    showLetters.forEach((_, index) => {
      timers.push(setTimeout(() => {
        setShowLetters(prev => {
          const newLetters = [...prev];
          newLetters[index] = true;
          return newLetters;
        });
      }, 2500 + index * 100)); // Start title letters animation one by one
    });

    timers.push(setTimeout(() => setShowLine(true), 3300)); // Show line after title letters animation

    showSubElements.forEach((_, index) => {
      timers.push(setTimeout(() => {
        setShowSubElements(prev => {
          const newSubElements = [...prev];
          newSubElements[index] = true;
          return newSubElements;
        });
      }, 3400 + index * 300)); // Show subtitle elements one by one
    });

    return () => timers.forEach(timer => clearTimeout(timer));
  }, []);

  return (
    <div>
      <div id="rolunk" className="rolunkhero ab">
        <div className="rolunkherocontainer flip">
          <div className="rolunkheroheadingdiv">
            <h1 className="rolunkheroheading">
              {["r", "ó", "l", "u", "n", "k"].map((letter, index) => (
                <span key={index} style={transformStyle1} className={`span-text ${showLetters[index] ? 'slide-up' : ''}`}>{letter}</span>
              ))}
            </h1>
            <div style={transformStyle2} className={`border-line intro ${showLine ? 'line-appear' : ''}`}></div>
          </div>
          <div
            data-poster-url="https://uploads-ssl.webflow.com/669e5bd0c85c4a7e55e1d179%2F669e79d2616863447dda5860_0722-poster-00001.jpg"
            data-video-urls="videos/0722-transcode.mp4,videos/0722-transcode.webm"
            data-autoplay="true"
            data-loop="true"
            data-wf-ignore="true"
            className={`video-bg w-background-video w-background-video-atom ${!scaleDone ? 'scale-down' : ''}`}
          >
            <video
              id="c7bea33e-de1a-03c8-38ff-e77179c7dd3e-video"
              autoPlay
              loop
              style={{ backgroundImage: 'url("https://uploads-ssl.webflow.com/669e5bd0c85c4a7e55e1d179%2F669e79d2616863447dda5860_0722-poster-00001.jpg")' }}
              muted
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              <source src="videos/0722-transcode.mp4" data-wf-ignore="true" />
              <source src="videos/0722-transcode.webm" data-wf-ignore="true" />
            </video>
          </div>
          <div className="div-block-3">
            <h2 className="heading-4">
              {["Ahol", "a", "tökéletesség", "és"].map((text, index) => (
                <span key={index} style={transformStyle1} className={`span-text ${14 + index} ${showSubElements[index] ? 'slide-up' : ''}`}>{text}</span>
              ))}
            </h2>
            <img
              className={`image-2 ${showSubElements[4] ? 'slide-up' : ''}`}
              src="images/arterisk.png"
              alt=""
              style={transformStyle1}
              sizes="(max-width: 479px) 100px, (max-width: 767px) 10vw, (max-width: 991px) 7vw, 6vw"
              data-w-id="7bb143dd-fb4e-bf7b-9a79-1bbad120d975"
              loading="lazy"
              srcSet="images/arterisk-p-500.png 500w, images/arterisk-p-800.png 800w, images/arterisk-p-1080.png 1080w, images/arterisk.png 1600w"
            />
            <h2 className="heading-5">
              {["a", "minőség", "találkozik"].map((text, index) => (
                <span key={index} style={transformStyle1} className={`span-text ${18 + index} ${showSubElements[4 + index] ? 'slide-up' : ''}`}>{text}</span>
              ))}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rolunk;