import React, { useState, useEffect, useRef } from "react";

const Section3 = () => {
  const [divBlockStyle, setDivBlockStyle] = useState({ width: '0%', transition: 'width 0.5s ease-in-out' });
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setDivBlockStyle((prev) => ({ ...prev, width: '100%' }));
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);
  return (
    <div ref={containerRef}>
        <div class="section-3">
      <div class="content2">
        <p class="paragraph-9">Akár elegáns alkalmakra, akár egy sima hétköznapra keresel darabokat, nálunk megtalálod azt, amire szükséged lesz. Csatlakozz hozzánk az Amarenti közösségében, és éld meg a stílusos és gondtalan<strong> </strong>elegancia érzését!</p>
        <div class="wearamarentiheadingcontainer">
          <h1 class="heading-6">#wearamarenti</h1>
          <div data-w-id="219a636f-b332-aa2b-42f9-2dd237d1c319" style={divBlockStyle} class="vonal2"></div>
        </div>
      </div>
    </div>
    <div class="section-3telo">
      <div class="content-2telo">
        <div class="wearamarentiheadingcontainer">
          <h1 class="heading-6">#wearamarenti</h1>
          <div data-w-id="50f1600e-f51e-1012-c086-ec49906ac7ed" style={divBlockStyle} class="vonal2"></div>
        </div>
        <p class="paragraph-9">Akár elegáns alkalmakra, akár egy sima hétköznapra keresel darabokat, nálunk megtalálod azt, amire szükséged lesz. Csatlakozz hozzánk az Amarenti közösségében, és éld meg a stílusos és gondtalan<strong> </strong>elegancia érzését!</p>
      </div>
    </div>
    </div>
  );
};

export default Section3;