import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { BaseProvider } from './contexts/BaseContext';
import './css/webflow.css';
import './css/mainWeb.css';
import './css/normalize.css';
import './App.css';
import MainPage from './Pages/Mainpage/MainPage';
import RolunkPage from './Pages/Rolunkpage/RolunkPage';
import TermekeinkPage from './Pages/Termekeink/TermekeinkPage';

const App = () => {
  const width = window.innerWidth;
  const isDesktop = width >= 1024;
  
  return (
          <BaseProvider>
          <Helmet>
            <title>Amarenti</title>
            <meta name="description" content="Ne csak viseld, élvezd is. Kényelmes, stílusos és egyedi ruhadarabok" />
            <meta property="og:title" content="Amarenti" />
            <meta property="og:description" content="Ne csak viseld, élvezd is. Kényelmes, stílusos és egyedi ruhadarabok" />
            <meta property="og:image" content="https://www.amarenti.hu/logo.png" />
            <meta property="og:url" content="https://www.amarenti.hu" />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
          <Router>
            <Routes>
              {isDesktop ? (
                <>
                  <Route path="/" element={<div style={{overflowX:"hidden"}}><MainPage/></div>} />
                  <Route path="/rolunk" element={<div style={{overflowX:"hidden"}}><RolunkPage/></div>} />
                  <Route path="/termekeink" element={<div style={{overflowX:"hidden"}}><TermekeinkPage/></div>} />
                </>
              ) : (
                <>
                  <Route path="/" element={<div style={{overflowX:"hidden"}}><MainPage/></div>} />
                  <Route path="/rolunk" element={<div style={{overflowX:"hidden"}}><RolunkPage/></div>} />
                  <Route path="/termekeink" element={<div style={{overflowX:"hidden"}}><TermekeinkPage/></div>} />
                </>
              )}
            </Routes>
            <div style={{height:"0vh",width:'0vw',overflow:'hidden'}}>
            </div>
          </Router>
          </BaseProvider>
  );
};

export default App;