import React from "react";
import NavRolunk from "./components/Nav";
import Rolunk from "./components/Rolunk";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Footer from "./components/Footer";


const RolunkPage = () => {
  
  return (
    <div>
        <body class="body-2">
            <div class="rolunk-wrap">
                <NavRolunk/>
                <Rolunk/>
                <Section1/>
                <Section2/>
                <Section3/>
                <Section4/>
                <Footer/>
            </div>
        </body>
    </div>
  );
};

export default RolunkPage;