import React, { useEffect, useRef, useState } from 'react';

const Section1 = () => {
  const initialTransformStyle = {
    WebkitTransform: 'translate3d(0, 150%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MozTransform: 'translate3d(0, 150%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MsTransform: 'translate3d(0, 150%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    transform: 'translate3d(0, 150%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    transition: 'transform 0.5s ease-in-out',
  };

  const [transformStyles, setTransformStyles] = useState(Array(9).fill(initialTransformStyle));
  const [divBlockStyle, setDivBlockStyle] = useState({ width: '0%', transition: 'width 0.5s ease-in-out' });
  const containerRef = useRef(null);

  useEffect(() => {
    const width = window.innerWidth;
    const isDesktop = width >= 1024;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            transformStyles.forEach((_, index) => {
              setTimeout(() => {
                if (isDesktop) {
                  setTransformStyles((prev) => {
                    const newStyles = [...prev];
                    newStyles[index] = {
                      ...initialTransformStyle,
                      transform: 'translate3d(0, 22px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                    };
                    return newStyles;
                  });
                } else {
                  setTransformStyles((prev) => {
                    const newStyles = [...prev];
                    newStyles[index] = {
                      ...initialTransformStyle,
                      transform: 'translate3d(0, 5px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                    };
                    return newStyles;
                  });
                }
              }, index * 100); // Adjust the delay time as needed
            });
            setDivBlockStyle((prev) => ({ ...prev, width: '100%' }));
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div>
      <div className="section-1" ref={containerRef}>
        <div data-w-id="ab8b516b-3446-7ce6-3f19-b2ca6f701a3b" className="content1">
          <div className="amarentiheadingwrap">
            <h1 className="heading-3">
              {['A', 'm', 'a', 'r', 'e', 'n', 't', 'i'].map((char, index) => (
                <span key={index} style={transformStyles[index]} className={`span-text ${char.toLowerCase()}`}>
                  {char}
                </span>
              ))}
            </h1>
            <div style={divBlockStyle} className="spacer-vonal"></div>
          </div>
          <p className="paragraph-8">
            Mi vagyunk az Amarenti csapata. Két fiatal elme szüleménye, akik megunták az átlagos modern öltözködési
            normákat. Magas minőségű bársony anyagokból készült ruhákkal foglalkozunk, célunk, hogy ne csak jól álljon
            neked, de kényelmes is legyen. Termékeinkben a felső kategóriás anyagok és egy tökéletes design találkozik,
            hogy így hozzuk létre ruhatárad legfényesebben csillogó darabját.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section1;