import React, { useState, useEffect, useRef } from 'react';
import { useBase } from '../../../contexts/BaseContext';

const Hero = () => {
  const {showInfo,toggleInfo,popupOpen,setPopupOpen,popupType,setPopupType, openPopup,closePopup} = useBase();
  const initialTransformStyle = {
    WebkitTransform: 'translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MozTransform: 'translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MsTransform: 'translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    transform: 'translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    transition: 'transform 0.5s ease-in-out',
  };

  const [transformStyles, setTransformStyles] = useState(Array(13).fill(initialTransformStyle));
  const [opacityStyle, setOpacityStyle] = useState({ opacity: 0, transition: 'opacity 0.5s ease-in-out' });
  const [divBlockStyle, setDivBlockStyle] = useState({ width: '0%', transition: 'width 0.5s ease-in-out' });
  const [hoverStyle, setHoverStyle] = useState({ height: '0%', transition: 'height 0.5s ease-in-out' });
  const [hoverStyle2, setHoverStyle2] = useState({ height: '0%', transition: 'height 0.5s ease-in-out' });
  const [hoverStyleColor, setHoverStyleColor] = useState({ color: "white", transition: 'color 0.5s ease-in-out' });
  const [hoverStyleColor2, setHoverStyleColor2] = useState({  color: "white", transition: 'color 0.5s ease-in-out' });

  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            transformStyles.forEach((_, index) => {
              setTimeout(() => {
                setTransformStyles((prev) => {
                  const newStyles = [...prev];
                  newStyles[index] = {
                    ...initialTransformStyle,
                    transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  };
                  return newStyles;
                });
              }, index * 100); // Adjust the delay time as needed
            });
            setOpacityStyle((prev) => ({ ...prev, opacity: 1 }));
            setDivBlockStyle((prev) => ({ ...prev, width: '100%' }));
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    setHoverStyle((prev) => ({ ...prev, height: '100%' }));
    setHoverStyleColor((prev) => ({ ...prev, color:"black" }));
  };

  const handleMouseLeave = () => {
    setHoverStyle((prev) => ({ ...prev, height: '0%' }));
    setHoverStyleColor((prev) => ({ ...prev, color:"white" }));
  };
  const handleMouseEnter2 = () => {
    setHoverStyle2((prev) => ({ ...prev, height: '100%' }));
    setHoverStyleColor2((prev) => ({ ...prev, color:"black" }));
  };

  const handleMouseLeave2 = () => {
    setHoverStyle2((prev) => ({ ...prev, height: '0%' }));
    setHoverStyleColor2((prev) => ({ ...prev, color:"white" }));
  };
  const setTermek = (num) => {
    if (num === 1) {
      setPopupType(1);
      openPopup();
      console.log(popupType);
    } else {
      setPopupType(2);
      openPopup();
      console.log(popupType);
    }

  }

  return (
    <div ref={containerRef}>
      <div className="hero-parent">
        <div id="termekeink" className="header-parent">
          <div className="grid-main text">
            <div
              id="w-node-_597ac81c-5054-b146-48cc-3d95806c41eb-d5c1a816"
              data-w-id="597ac81c-5054-b146-48cc-3d95806c41eb"
              className="parent-copy"
            >
              <div className="text-parent">
                <h1 className="serif">
                  Magas minőségű bársony <br />
                  anyagokból készült ruhák
                </h1>
                <div className="rolunkheroheadingdiv">
                  <div id="w-node-_1de6c7c2-a805-19c2-22ef-2bf1e9b274c3-d5c1a816" className="text-sm">
                    by amarenti
                  </div>
                </div>
              </div>
              <div id="w-node-_616217a1-f826-255e-45c0-0113dff5ddf2-d5c1a816" className="parent flex-split">
                <div className="rolunkheroheadingdiv full-right">
                  <h2>
                    {['#', 'w', 'e', 'a', 'r', 'a', 'm', 'a', 'r', 'e', 'n', 't', 'i'].map((char, index) => (
                      <span key={index} style={transformStyles[index]} className={`span-text ${index + 1}`}>
                        {char}
                      </span>
                    ))}
                  </h2>
                </div>
                <a data-w-id="616217a1-f826-255e-45c0-0113dff5ddff" style={opacityStyle} className="primary-btn ghost w-inline-block" onClick={()=>showInfo()}>
                  <div className="btn-whipe bg-white"></div>
                    <div className="button-text">
                      visszatérítés
                      <br />
                    </div>
                </a>
              </div>
            </div>
            <div data-w-id="e6c2c4d8-6620-12a6-1b6c-7d3c774df240" style={divBlockStyle} className="div-block"></div>
          </div>
        </div>
        <div className="grid-main image">
          <div
            id="w-node-_79ecd13d-6864-2c8e-fb8d-68c1d705f0b5-d5c1a816"
            data-w-id="79ecd13d-6864-2c8e-fb8d-68c1d705f0b5"
            className="rolunkhero bg-blue"
            
          >
            <img
              src="images/dfae22_b030a0fc8f264a77ace1f4c63539d068~mv2.webp"
              loading="lazy"
              sizes="(max-width: 479px) 64vw, (max-width: 767px) 63vw, (max-width: 991px) 31vw, 26vw"
              srcSet="images/dfae22_b030a0fc8f264a77ace1f4c63539d068~mv2-p-500.webp 500w, images/dfae22_b030a0fc8f264a77ace1f4c63539d068~mv2.webp 604w"
              alt=""
              className="termekkep1"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={()=>setTermek(1)}
            />
            <div style={hoverStyle} className="bghover"></div>
            <div className="namecontainer">
              <p className="paragraph-3" style={hoverStyleColor}>Amarenti </p>
              <p className="paragraph-5" style={hoverStyleColor}>Black T-shirt</p>
            </div>
          </div>
          <div
            id="w-node-f25cacf6-2867-1b9f-0903-a15f32fca708-d5c1a816"
            data-w-id="f25cacf6-2867-1b9f-0903-a15f32fca708"
            className="rolunkhero bg-pink"
            
          >
            <img
              src="images/dfae22_e2e45c9ccb644525bb37d0b7016c1da7~mv2.webp"
              loading="lazy"
              sizes="(max-width: 479px) 64vw, (max-width: 767px) 63vw, (max-width: 991px) 31vw, 26vw"
              srcSet="images/dfae22_e2e45c9ccb644525bb37d0b7016c1da7~mv2-p-500.webp 500w, images/dfae22_e2e45c9ccb644525bb37d0b7016c1da7~mv2.webp 604w"
              alt=""
              className="termekkep2"
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
              onClick={()=>setTermek(2)}
            />
            <div className="namecontainer">
              <p className="paragraph-3" style={hoverStyleColor2}>
                Amarenti
                <br />
              </p>
              <p data-w-id="4ce97033-b577-f46f-7cd1-ea1b4a6e7728" className="paragraph-6" style={hoverStyleColor2}>
                Midnightblue T-shirt
              </p>
            </div>
            <div style={hoverStyle2} className="bghover"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;