import React, { useState } from "react";
import { useBase } from "../../../contexts/BaseContext";

const Info = () => {
  const {transStyle,hideInfo} = useBase();
  return (
    <div>
        <div style={{display:"none",opacity:"0"}} class="outro-fade"></div>
    <div data-w-id="a54c089b-4434-a255-3ea1-1cdc0756e3ee" style={transStyle} class="comp-info">
      <div class="comp-scroll">
        <div class="comp-info-parent">
          <div class="title-parent">
            <a data-w-id="7211ebf3-187f-a4c5-8bbc-5c1e765792eb" onClick={()=>hideInfo()} class="primary-btn ghost dark w-inline-block">
              <div style={{WebkitTransform:"translate3d(0, 102%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",MozTransform:"translate3d(0, 102%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",MsTransform:"translate3d(0, 102%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",transform:"translate3d(0, 102%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"}} class="btn-whipe bg-white"></div>
              <div class="rolunkheroheadingdiv" >
                <div class="button-text text-black">bezár</div>
                <div class="button-text-ab text-black">Bezár</div>
              </div>
            </a>
            <h2 class="hero-title-contact text-black">Hogyan működik a visszaszállítás?</h2>
          </div>
          <div>
            <div class="list-parent">
              <div class="number">01</div>
              <p class="pull-down">Olvasd el a szállítás és visszatérítési szabályzatunkat - <a href="#" class="link">Tudj meg többet. </a>
              </p>
            </div>
            <div class="list-parent">
              <div class="number">02</div>
              <p class="pull-down">Ha továbbra is vissza szeretnéd küldeni a terméket, Emailben vagy telefonfon jelezd nekünk.  </p>
            </div>
            <div class="list-parent">
              <div class="number">03</div>
              <p class="pull-down">Postázd el nekünk a Nagykanizsa xy címre.</p>
            </div>
            <div class="list-parent no-broder">
              <div class="number">04</div>
              <p class="pull-down">Mi visszaküldjük az általad választott színben és méretben a terméket </p>
            </div>
          </div>
        </div>
        <div class="info-col-parent-pop-up">
          <div class="info-col-child pop-up">
            <h2 class="text-black">Túl nagy vagy kicsi?</h2>
            <p class="text-black-copy">Semmi baj, jelezd nekünk, és mi próbáljuk a lehető leggyorsabban kicserélni neked a terméket. A szállítási költség a vásárlót terheti. </p>
            <div class="scroll-text">Csúsztasd az ujjaddal&gt;&gt;</div>
          </div>
          <div class="border"></div>
          <div class="info-col-child pop-up">
            <h2 class="text-black">Más színben szeretnéd?</h2>
            <p class="text-black-copy">Ha esetleg más színben szeretnéd a termékünket, üzenj nekünk Emailben vagy telefonon érj el minket és mindent megteszünk, hogy zökkenő mentesen kicseréljük a terméket.</p>
          </div>
          <div class="border"></div>
          <div class="info-col-child pop-up">
            <h2 class="text-black">Nincs szükséged rá?</h2>
            <p class="text-black-copy">Ha mégsem szeretnéd a terméket, üzenj nekünk Emailben vagy telefonon keresztül, és próbáljuk minél előbb orvosolni a problémát.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Info;