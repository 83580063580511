import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Experiences = () => {
  const navigate = useNavigate();

  const handleClickHref = (link) => {
    navigate(link);
  };
  const transformStyle1 = {
    opacity: 1,
    WebkitTransform: 'translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MozTransform: 'translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MsTransform: 'translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    transform: 'translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
  };

  const transformStyle2 = {
    WebkitTransform: 'translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MozTransform: 'translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MsTransform: 'translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    transform: 'translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
  };

  const transformStyle3 = {
    WebkitTransform: 'translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MozTransform: 'translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MsTransform: 'translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    transform: 'translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
  };

  const transformStyle4 = {
    WebkitTransform: 'translate3d(0, 102%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MozTransform: 'translate3d(0, 102%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MsTransform: 'translate3d(0, 102%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    transform: 'translate3d(0, 102%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
  };

  useEffect(() => {
    const videos = document.querySelectorAll('.w-background-video video');
    videos.forEach(video => {
      video.oncanplaythrough = () => {
        video.play().catch(() => {}); // Catch the error to avoid unhandled promise rejection
      };
    });
  }, []);

  return (
    <div id="Experiences" className="section split-col">
      <div style={{ display: 'none' }} className="intro-cover"></div>
      <div className="background-content">
        <div className="introhead-wrap">
          <h1 data-w-id="d529b89a-2d03-d449-1b35-93b07a117d50" style={{}} className="title-name-intro-2">
            Amarenti
          </h1>
        </div>
      </div>
      <div data-w-id="4ad2aabb-3611-1662-78ae-e9e63628d393" style={{}} className="col-left">
        <div className="col-left-inner">
          <div className="col-content-parent" onClick={()=>handleClickHref("/rolunk")}>
            <div className="parent class-type">
              <div className="rolunkheroheadingdiv">
                <h2 className="heading">
                  <span data-w-id="c98180cc-b52b-a5f0-bdb1-f6e9aad96a3a" className="span-text one">R</span>
                  <span data-w-id="a45ad011-31ae-428d-ac94-c051105f36b1" className="span-text two">ó</span>
                  <span data-w-id="7afaf30e-8e20-271c-6ec0-bb1e8819ce6d" className="span-text three">l</span>
                  <span data-w-id="f771d5f7-1215-19c8-eaed-88bd04a85451" className="span-text four">u</span>
                  <span data-w-id="ec654e9a-d0b0-f2f3-ad3b-2ef5c02a7451" className="span-text four">n</span>
                  <span data-w-id="e700faaf-8970-09ac-e466-97a785a4b4e4" className="span-text five">k</span>
                </h2>
              </div>
              <div data-w-id="4ad2aabb-3611-1662-78ae-e9e63628d399" className="tag-sm intro">#WEARAMARENTI</div>
            </div>
            <div className="info-col-parent">
              <div data-w-id="4ad2aabb-3611-1662-78ae-e9e63628d39c" style={{}} className="info-col-child">
                <p className="paragraph">Ne csak viseld, élvezd is. Kényelmes, stílusos és egyedi ruhadarabok - Amarenti</p>
                <a data-w-id="4ad2aabb-3611-1662-78ae-e9e63628d3a4" href="/rolunk" className="primary-btn ghost w-inline-block">
                  <div style={{}} className="btn-whipe bg-white"></div>
                    <div className="button-text">Tudj meg többet</div>
                </a>
              </div>
            </div>
          </div>
          <div className="rolunkhero ab" >
            <div className="rolunkherocontainer" >
              <div
                data-poster-url="https://uploads-ssl.webflow.com/669e5bd0c85c4a7e55e1d179%2F669e79d2616863447dda5860_0722-poster-00001.jpg"
                data-video-urls="videos/0722-transcode.mp4,videos/0722-transcode.webm"
                data-autoplay="true"
                data-loop="true"
                data-wf-ignore="true"
                data-w-id="6a2545ed-54ee-3d66-b346-d0d06b65b0a0"
                className="video-bg w-background-video w-background-video-atom"
              >
                <video
                  id="6a2545ed-54ee-3d66-b346-d0d06b65b0a0-video"
                  autoPlay
                  loop
                  style={{
                    backgroundImage: 'url("https://uploads-ssl.webflow.com/669e5bd0c85c4a7e55e1d179%2F669e79d2616863447dda5860_0722-poster-00001.jpg")'
                  }}
                  muted
                  playsInline
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source src="videos/0722-transcode.mp4" data-wf-ignore="true" />
                  <source src="videos/0722-transcode.webm" data-wf-ignore="true" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-w-id="4ad2aabb-3611-1662-78ae-e9e63628d3ae" style={{}} className="col-right" >
        <div className="col-left-inner">
          <div className="col-content-parent" onClick={()=>handleClickHref("/termekeink")}>
            <div style={{}} className="parent">
              <div className="rolunkheroheadingdiv">
                <h2 className="heading-2">
                  <span data-w-id="8b8ca443-0f67-3493-d71e-4f904b561caf" className="span-text one">V</span>
                  <span data-w-id="fd4fb9ae-ecf0-594e-2232-ab842ded2e39" className="span-text two">á</span>
                  <span data-w-id="3cfbd9b1-2785-6058-205b-3a4d508c3441" className="span-text three">s</span>
                  <span data-w-id="8f3c4b73-b002-8348-ffbd-83a9965a720d" className="span-text four">á</span>
                  <span data-w-id="a221a32c-89b3-747f-2a74-5286fa8167a3" className="span-text five">r</span>
                  <span data-w-id="a3b0cc6f-c0c4-6af7-8360-045389d16d14" className="span-text six">l</span>
                  <span data-w-id="d69fca78-4a51-5e88-d8f3-70cc46c18a8b" className="span-text seven">á</span>
                  <span data-w-id="82dd755c-b856-d4ba-51f5-c5c1c2c50404" className="span-text eight">s</span>
                </h2>
              </div>
              <div data-w-id="4ad2aabb-3611-1662-78ae-e9e63628d3b4" className="tag-sm intro">#WEARAMARENTI</div>
            </div>
            <div className="info-col-parent">
              <div data-w-id="4ad2aabb-3611-1662-78ae-e9e63628d3b7" style={{}} className="info-col-child">
                <p className="paragraph-2">Vásárolj most, és tapasztald meg a különbséget! Kényelmes, stílusos ruhák - Amarenti.</p>
                <a data-w-id="4ad2aabb-3611-1662-78ae-e9e63628d3bf" href="/termekeink" className="primary-btn ghost w-inline-block">
                  <div style={{}} className="btn-whipe bg-white"></div>
                    <div className="button-text">nézz körbe</div>
                </a>
              </div>
            </div>
          </div>
          <div className="rolunkhero ab">
            <div className="rolunkherocontainer">
              <div
                data-poster-url="https://uploads-ssl.webflow.com/669e5bd0c85c4a7e55e1d179%2F669e7ca2017c8a8f795c889a_0722%281%29-poster-00001.jpg"
                data-video-urls="videos/07221-transcode.mp4,videos/07221-transcode.webm"
                data-autoplay="true"
                data-loop="true"
                data-wf-ignore="true"
                data-w-id="074445b6-3bd8-02e0-0443-ae7c544e6ae1"
                className="video-bg w-background-video w-background-video-atom"
              >
                <video
                  id="074445b6-3bd8-02e0-0443-ae7c544e6ae1-video"
                  autoPlay
                  loop
                  style={{
                    backgroundImage: 'url("https://uploads-ssl.webflow.com/669e5bd0c85c4a7e55e1d179%2F669e7ca2017c8a8f795c889a_0722%281%29-poster-00001.jpg")'
                  }}
                  muted
                  playsInline
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source src="videos/07221-transcode.mp4" data-wf-ignore="true" />
                  <source src="videos/07221-transcode.webm" data-wf-ignore="true" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experiences;