import React from "react";

const Section2 = () => {
  
  return (
    <div>
        <div class="section-2">
      <div class="marquee-horizontal">
        <div class="marquee-ccs w-embed">
        </div>
        <div class="track-horizontal-alt">
          <div class="marquee-text-2">Hordj Amarentit</div>
          <div class="marquee-text-2">Wear Amarenti</div>
          <div class="marquee-text-2">Trage Amarenti</div>
          <div class="marquee-text-2">Lleva Amarenti</div>
          <div class="marquee-text-2">Nosite Amarenti</div>
          <div class="marquee-text-2">portez Amarenti</div>
          <div class="marquee-text-2">indossa Amarenti</div>
          <div class="marquee-text-2">vishni Amarenti</div>
          <div class="marquee-text-2">носите Амаренти</div>
          <div class="marquee-text-2">ارتدِ أميرنتي</div>
          <div class="marquee-text-2">bär Amarenti</div>
          <div class="marquee-text-2">Amarenti giy</div>
          <div class="marquee-text-2">noś Amarenti</div>
          <div class="marquee-text-2">φόρεσε αμαρέντι</div>
          <div class="marquee-text-2">draag amarenti</div>
        </div>
      </div>
      <div class="marquee-horizontal-2">
        <div class="marquee-horizontal-css w-embed">
        </div>
        <div class="track-horizontal">
          <div class="marquee-text-3">Հագեք Ամարենտի</div>
          <div class="marquee-text-3">Porta Amarenti</div>
          <div class="marquee-text-3">アマレンティを着てください</div>
          <div class="marquee-text-3">穿上阿马伦蒂</div>
          <div class="marquee-text-3">Амараентиг өмс</div>
          <div class="marquee-text-3">अमरेन्टी पहनें</div>
          <div class="marquee-text-3">ใส่ Amarenti</div>
          <div class="marquee-text-3">იცვით ამარენტი</div>
          <div class="marquee-text-3">ללבוש אמרנטי</div>
          <div class="marquee-text-3">آمارنتی بپوشید</div>
          <div class="marquee-text-3">Amarenti kiyu</div>
          <div class="marquee-text-3">Amarenti biqin</div>
          <div class="marquee-text-3">kanna Amarentit</div>
          <div class="marquee-text-3">gwisgo Amarenti</div>
          <div class="marquee-text-3">pakai Amarenti</div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Section2;