import React from "react";
import Nav from "./components/Nav";
import Experiences from "./components/Experiences";

const MainPage = () => {
  
  return (
    <div>
        <body class="body">
            <div class="home-wrap">
                <Nav/>
                <Experiences/>
            </div>
        </body>
    </div>
  );
};

export default MainPage;