import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const Section4 = () => {
  const [divBlockStyle, setDivBlockStyle] = useState({ width: '0%', transition: 'width 0.5s ease-in-out' });
  const [showCards, setShowCards] = useState([false, false]);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const handleClickHref = (link) => {
    navigate(link);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setDivBlockStyle((prev) => ({ ...prev, width: '100%' }));
            setTimeout(() => setShowCards([true, true]), 500); // Delay the cards' appearance
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const cardStyle = (index) => ({
    WebkitTransform: showCards[index] ? "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" : "translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MozTransform: showCards[index] ? "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" : "translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MsTransform: showCards[index] ? "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" : "translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transform: showCards[index] ? "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" : "translate3d(0, 110%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transition: 'transform 0.5s ease-in-out'
  });

  return (
    <div>
      <div className="section-4" ref={containerRef}>
        <div className="termekeinkheadingcontainer">
          <h1 className="heading-8">Termékeink</h1>
          <div data-w-id="42f27680-cc42-9060-7788-1bf74d53388f" style={divBlockStyle} className="vonal"></div>
        </div>
        <div data-w-id="c9263c4e-d0c6-57f2-ae5f-33bb87f13beb" className="kartya-container" onClick={()=>handleClickHref("/termekeink")}>
          <div data-w-id="d3d79921-3729-f715-6f93-e162b9f3cd2b" style={cardStyle(0)} className="kartya-1">
            <div className="kartyaheading-container">
              <h2 className="heading-7">Amarenti</h2>
              <div className="div-block-4"></div>
            </div>
            <p className="paragraph-10">Black T-shirt<br /></p>
            <div className="kep-container"><img src="images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 38vw, 36vw" alt="" srcSet="images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2-p-500.webp 500w, images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2-p-800.webp 800w, images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2-p-1080.webp 1080w, images/dfae22_311e5dc6b52c4993bf592c0b394c561e~mv2.webp 1380w" className="termekkep1rolunk" /></div>
          </div>
          <div data-w-id="385d0853-870c-5ea4-d1b8-e90c2e2d0dd1" style={cardStyle(1)} className="kartya-2">
            <div className="kartyaheading-container">
              <h2 className="heading-7">Amarenti</h2>
              <div className="div-block-4"></div>
            </div>
            <p className="paragraph-11">Midnightblue T-shirt<br /></p>
            <div className="kep-container"><img src="images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 38vw, 36vw" alt="" srcSet="images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2-p-500.webp 500w, images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2-p-800.webp 800w, images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2-p-1080.webp 1080w, images/dfae22_faab33ac777d4bc8b503cb1fd5499a24~mv2.webp 1380w" className="termekkep2rolunk" /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;