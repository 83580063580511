import React, { useEffect, useRef, useState } from "react";

const NavTermek = () => {
  const [menuVisible, setMenuVisible] = useState(false);
    const [menuClasses, setMenuClasses] = useState(['', '', '', '']);
    const timeouts = useRef([]);
  
    const handleMenuToggle = () => {
      setMenuVisible(!menuVisible);
      if (!menuVisible) {
        showMenusWithDelay();
      } else {
        hideMenus();
      }
    };
  
    const showMenusWithDelay = () => {
      clearTimeouts();
      const delays = [100, 200, 300, 400];
      delays.forEach((delay, index) => {
        const timeout = setTimeout(() => {
          setMenuClasses(prev => {
            const newClasses = [...prev];
            newClasses[index] = 'visible';
            return newClasses;
          });
        }, delay);
        timeouts.current.push(timeout);
      });
    };
  
    const hideMenus = () => {
      clearTimeouts();
      setMenuClasses(['', '', '', '']);
    };
  
    const clearTimeouts = () => {
      timeouts.current.forEach(clearTimeout);
      timeouts.current = [];
    };
  
    useEffect(() => {
      return () => clearTimeouts();
    }, []);

  return (
    <div>
      <div style={{ display: "none", opacity: "0" }} className="outro-fade"></div>
      <div data-w-id="c74f2bdc-653e-4a88-1cea-c3933179f0ec" className="nav-bar">
        <div className="grid-main">
          <a id="w-node-_7bc8e676-29f4-2778-c870-27f70802723c-d5c1a814" href="/" aria-current="page" className="w-inline-block w--current">
            <img src="images/logo.png" loading="lazy" sizes="(max-width: 479px) 44vw, (max-width: 767px) 31vw, (max-width: 991px) 20vw, 16vw" srcSet="images/logo-p-500.png 500w, images/logo-p-800.png 800w, images/logo.png 941w" alt="" className="logo" />
          </a>
          <div id="w-node-_222484a3-18fe-6908-b0c0-9cb009a0648f-d5c1a814" className="flex-menu">
            <div className="tag-sm nav">Amarenti</div>
            <div data-w-id="222484a3-18fe-6908-b0c0-9cb009a06492" className="menu-parent" onClick={handleMenuToggle}>
              <div className="menu-bar top"></div>
              <div className="menu-bar bottom"></div>
            </div>
            <div className={`menu-scroll-hide ${menuVisible ? 'visible' : ''}`}>
              <div className="menu-drop-down">
                <a data-w-id="d6ab7780-b7f5-b8e7-0e27-683c88afcec3" className={`primary-btn menu one w-inline-block ${menuClasses[0]}`}  href="/termekeink">
                  <div className="btn-whipe bg-white"></div>
                    <div className="button-text text-white">TERMÉKEINK</div>
                </a>
                <a data-w-id="e18ca577-8668-4a27-2267-1184e65bb271" className={`primary-btn menu two w-inline-block ${menuClasses[1]}`} href="/rolunk">
                  <div className="btn-whipe bg-white"></div>
                    <div className="button-text text-white">rólunk</div>
                </a>
                <a data-w-id="551ba213-89f0-b208-f54b-725ac995bfbd" className={`primary-btn menu three w-inline-block ${menuClasses[2]}`} href="/">
                  <div className="btn-whipe bg-white"></div>
                    <div className="button-text text-white">Főoldal</div>
                </a>
                <a data-w-id="def12ac0-3357-2cae-1e46-a4a5f7740072" className={`primary-btn menu four w-inline-block ${menuClasses[3]}`} target="_blank" href='https://www.instagram.com/amarentiofficial/'>
                  <div className="btn-whipe bg-white"></div>
                    <div className="button-text text-white">instagram</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavTermek;