import React, { useEffect, useRef, useState } from "react";

const FooterTermek = () => {
  const containerRef = useRef(null);
  const [divBlockStyle, setDivBlockStyle] = useState({ width: '0%', transition: 'width 0.5s ease-in-out' });
  const [divBlockStyle2, setDivBlockStyle2] = useState({ width: '0%', transition: 'width 0.5s ease-in-out' });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setDivBlockStyle((prev) => ({ ...prev, width: '100%' }));
            setDivBlockStyle2((prev) => ({ ...prev, width: '100%' }));
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll('.kezd-lap-text, .footerszoveg, .csomagtext-2, .csomagtartalma-text-2, .instatext, .fbtext, .tttext');

    const handleMouseOver = (e) => {
      elements.forEach(el => {
        if (el !== e.target) {
          el.classList.add('grey-text');
        }
      });
    };

    const handleMouseOut = () => {
      elements.forEach(el => {
        el.classList.remove('grey-text');
      });
    };

    elements.forEach(el => {
      el.addEventListener('mouseover', handleMouseOver);
      el.addEventListener('mouseout', handleMouseOut);
    });

    return () => {
      elements.forEach(el => {
        el.removeEventListener('mouseover', handleMouseOver);
        el.removeEventListener('mouseout', handleMouseOut);
      });
    };
  }, []);

  return (
    <div>
      <div className="footer" ref={containerRef}>
        <h1 className="footer-head">Amarenti</h1>
        <div data-w-id="69a1c09b-58c1-d3dc-9743-9352e905597d" style={divBlockStyle} className="footervonal1"></div>
        <div className="div-block-25">
          <div className="menupontok-container">
            <div id="w-node-b516d4c7-c7df-ee3c-64b2-205c5589a43c-d5c1a815" className="footer-jobb-2">
              <h3 className="heading-11">Menüpontok</h3>
              <a href="/" data-w-id="b516d4c7-c7df-ee3c-64b2-205c5589a43d" className="instalink w-inline-block">
                <div className="kezd-lap-text footerszoveg">Kezdőlap</div>
              </a>
              <a href="/rolunk" data-w-id="b516d4c7-c7df-ee3c-64b2-205c5589a441" className="csomag-link w-inline-block">
                <div className="csomagtext-2">Rólunk</div>
              </a>
              <a href="/termekeink" data-w-id="b516d4c7-c7df-ee3c-64b2-205c5589a445" className="csomag-tartalma w-inline-block">
                <div className="csomagtartalma-text-2">Termékeink</div>
              </a>
            </div>
          </div>
          <div className="socials-container">
            <h3 className="heading-12">Közösségi média</h3>
            <a href="https://www.instagram.com/amarentiofficial/" target="_blank" data-w-id="42c13b53-d73b-190d-b900-ce0df965fe97" className="instalink w-inline-block">
              <div className="instatext">Instagram</div>
            </a>
            <a href="https://www.facebook.com/people/Amarenti/61556762142512/" target="_blank" data-w-id="735a193c-fa1f-7a98-84bb-efef285d2536" className="fblink w-inline-block">
              <div className="fbtext">Facebook</div>
            </a>
            <a href="https://www.tiktok.com/@amarenti" target="_blank" data-w-id="d42876e5-d4f3-c756-62f0-e2682f582563" className="tiktoklink w-inline-block">
              <div className="tttext">TikTok</div>
            </a>
          </div>
          <a href="/termekeink" className="felazoldaltetejerelink w-inline-block">
            <h3 className="felazoldaltetejerehead">Fel az oldal tetejére</h3>
          </a>
        </div>
        <div data-w-id="302b3389-42d6-77e1-efaa-745cbe0444b5" style={divBlockStyle2} className="footervonal2"></div>
        <div className="div-block-28">
          <div className="aszf-szallitas">
            <a href="#" className="aszflink w-inline-block">
              <div className="aszftext">ÁSZF</div>
            </a>
            <a href="#" className="szallitaslink w-inline-block">
              <div className="szallitastext">Szállítás és visszatérítés</div>
            </a>
          </div>
          <div>
            <div className="text-block-3">
              <div href="https://www.gmamedia.co/" className="text-block-4">©2024 GMA MEDIA. MINDEN JOG FENNTARTVA.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTermek;