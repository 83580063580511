import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
const CheckoutForm = forwardRef((props, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event) => {
    event.preventDefault();
    if (true) {
      const cardElement = elements.getElement(CardElement);
      const result = await stripe.createSource(cardElement);
      if (result.error) {
        console.error(result.error.message);
      } else {
        const sourceId = result.source.id;
        const chargeDetails = {
          source: "sourceId",
          amount: "price * 100",
          reservationName: "name",
          resDateString: "dateStr",
          tajNum: "taj",
          postalCode: "postalCode",
          city: "city",
          adress: "address",
          country: "country",
          email: "email",
          phone: "phone,",
          comment: "form.other",
          descr: "amount + " - " + color",
          resStartIntervall: "selectedTime",
          resIntervallLenght: "intervalLenght",
          resAllIntervall: "selectedService"
        };
        axios.post('https://katadent.hu:41806/api/payments/charge', chargeDetails, {
          headers: {
            Authorization: `Bearer ${"AddictedAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
          .then(response => {
            console.log('Payment Success:', response.data);
            const { docid, status } = response.data;  // Extracting docid and status from the response
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
        });
      }
    } else {
        console.log("Form error");
    }
  };

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit({ preventDefault: () => {} });
    }
  }));

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={{ style: { base: { fontSize: '24px', padding: '10px' } } }}/>
    </form>
  );
});

export default CheckoutForm;